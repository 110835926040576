/* .frame-temp{
  width: 0px;
}

.display--flex{
display: flex;
flex-wrap: nowrap;
flex-direction: column;
}

.margin--10px{
margin: 10px 10px;
}

.margin_right{
width: 140px;
}

.container{
position: relative;
width: 620px;
height: 800px;
}

.item{
position: absolute;
} */

.outer {
  margin: 10px;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .person_image {
  z-index: 0;
}
.outer .canvas_2 {
  /* opacity: 0.5; */
  z-index: 1;
}
.outer .canvas_1 {
  /* opacity: 0.5; */
  z-index: 2;
}

.outer .only_seg_canvas{
  z-index: 3;
}

.outer .overlap_region{
  z-index: 5;
}

.outer .segmentations_region{
  z-index: 6;
}